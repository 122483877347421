import React, { Fragment } from "react"
import SEO from '../../components/seo';
import { ResetCSS } from '../../assets/css/style';
import { ThemeProvider } from "styled-components"
import { theme } from "../../theme"
import { ContentWrapper, GlobalStyle, NoticeHeading } from "../../containers/notice.style"
import { graphql, useStaticQuery } from "gatsby"
import Text from "../../components/Text"

const PrivacyPage = () => {
  const { noticesPrivacyYaml: data } = useStaticQuery(graphql`
    query {
      noticesPrivacyYaml {
        title
        content
      }
    }
  `);

  return (
    <ThemeProvider theme={theme}>
      <Fragment>
        <SEO title={`Tasky - ${data.title}`}/>

        <ResetCSS/>
        <GlobalStyle/>

        <ContentWrapper>
          <NoticeHeading content={data.title}/>
          <Text as="div" dangerouslySetInnerHTML={{ __html: data.content }}/>
        </ContentWrapper>

      </Fragment>
    </ThemeProvider>
  );
}

export default PrivacyPage;
